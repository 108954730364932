import React from 'react';
import { ListM } from '@alphakits/icons';
import { IconButton, showError } from '@alphakits/ui/dist';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { useApi } from 'src/providers/api/context';

export const GenerateYTeaser = ({ id }: { id: number }) => {
    const { questrooms } = useApi();

    const { generateTeaserFetch, generateTeaserLoading } = useApiMethod({
        api: questrooms.generateTeaser,
        errorCallback: ({ message }) => showError(message),
    });

    return (
        <IconButton
            icon={ ListM }
            loading={ generateTeaserLoading }
            onClick={ (e) => {
                e.stopPropagation();
                generateTeaserFetch(id);
            } }
        />
    );
};
