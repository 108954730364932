import React from 'react';
import { Table } from '@alphakits/ui';
import { useApiMethod } from '@escapenavigator/services/dist/hooks/use-api-method';
import { useApi } from 'src/providers/api/context';
import { DefaultPageProps } from 'src/types';

import { ordersColumns } from './columns';

export const AggregatorsPage: React.FC<DefaultPageProps> = () => {
    const { orders } = useApi();

    const { queryAgregaotrOrderFetch } = useApiMethod({
        api: orders.queryAgregaotrOrder,
    });

    return (
        <React.Fragment>
            <Table.TableComponent
                columns={ ordersColumns() }
                searchPlaceholder="Serach"
                language="en"
                promise={ queryAgregaotrOrderFetch }
            />
        </React.Fragment>
    );
};
