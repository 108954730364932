import React from 'react';
import { CopyButton, formatDate, Link } from '@alphakits/ui';
import { TableColumns } from '@alphakits/ui/dist/table/types';
import { RegistrationRequestResponseObject } from '@escapenavigator/types/dist/registration-request/registration-request.ro';

// type Language = 'RU' | 'EN' | 'DE' | string;

// const getDateColumnSubtitle: GetDateColumnSubtitle = (
//     date,
//     currentLanuage = 'RU',
//     withNegative,
// ) => {
//     const normalizedDate = new Date(date);

//     if (!isDate(normalizedDate)) return null;

//     const isRedColorOfText = Math.abs(differenceInDays(normalizedDate, new Date())) >= 6;

//     const distance = formatDistance(normalizedDate, new Date(), {
//         addSuffix: true,
//         locale: languageSchema[currentLanuage],
//     });

//     return (
//         <Typography.Text
//             color={ isRedColorOfText && withNegative ? 'negative' : 'secondary' }
//             view="caps"
//             weight="medium"
//         >
//             { distance }
//         </Typography.Text>
//     );
// };

export const columns = (): TableColumns<RegistrationRequestResponseObject> => [
    {
        header: 'ID',
        accessor: 'id',
    },
    {
        header: 'Date',
        accessor: 'createdAt',
        sort: true,
        row: {
            title: ({ row }) => formatDate(row.createdAt, {}),
        },
    },
    {
        header: 'Phone',
        accessor: 'phone',
    },

    {
        header: 'Email',
        accessor: 'email',
        row: {
            title: ({ row }) => <CopyButton fullText={ row.email } text={ row.email } />,
        },
    },
    {
        header: 'Status',
        accessor: 'status',
        sort: true,
    },
    {
        header: 'Website',
        accessor: 'site',
        row: {
            title: ({ row }) => (
                <Link target="_blank" href={ row.site }>
                    { row.site }
                </Link>
            ),
        },
    },
    {
        header: 'Country',
        accessor: 'country',
        sort: true,
    },
    {
        header: 'Comment',
        accessor: 'comment',
        sort: true,
    },
];
