import React, { useContext } from 'react';
import { ModalContext, Table } from '@alphakits/ui';
import { CityModal } from 'src/modals/city';
import { CitiesFilterModal } from 'src/modals/filters/city';
import { useApi } from 'src/providers/api/context';
import { DefaultPageProps } from 'src/types';

import { columns } from './columns';

export const CitiesPage: React.FC<DefaultPageProps> = () => {
    const { openModal } = useContext(ModalContext);

    const openRequest = openModal(CityModal);

    const { cities } = useApi();

    return (
        <React.Fragment>
            <Table.TableComponent
                onRowClick={ ({ id }, cb) => openRequest({ recordId: +id, cb }) }
                onCreateClick={ () => openRequest() }
                columns={ columns }
                filtersButtonLabel="Filters"
                onFiltersClick={ (props) => openModal(CitiesFilterModal, 's')(props) }
                searchPlaceholder="Serach by title"
                language="en"
                promise={ cities.query }
            />
        </React.Fragment>
    );
};
