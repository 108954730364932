import React, { useContext } from 'react';
import {
    Button,
    FlexColumns,
    Input,
    ModalContext,
    Select,
    Textarea,
    useCrudFormRequests,
    useGetOne,
} from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { RegistrationRequestStatusEnum } from '@escapenavigator/types/dist/registration-request/enum/registration-request-status';
import { UpdateRegistrationRequestDto } from '@escapenavigator/types/dist/registration-request/update-registration-request.dto';
import { serializeRecord, validateByDto } from '@escapenavigator/utils/dist';
import { enumToOptions } from '@escapenavigator/utils/dist/enum-to-options';
import { TFunction } from 'i18next';
import { useApi } from 'src/providers/api/context';

import { OrganizationModal } from '../organization';

type RequestModalProps = {
    close: () => void;
    cb: () => void;
    t: TFunction;
    recordId: number;
};

export const RegistrationRequestModal = ({
    close, t, recordId, cb,
}: RequestModalProps) => {
    const { registrationRequests } = useApi();
    const { openModal } = useContext(ModalContext);

    const openOrganizationModal = openModal(OrganizationModal);

    const { record, loading } = useGetOne(+recordId, registrationRequests.getOne);

    const { save, remove, updating } = useCrudFormRequests({
        saveRequest: registrationRequests.update,
        recordId: +recordId,
        close,
        saveCallback: cb,
        removeCallback: cb,
    });

    return (
        <RestForm
            t={ t }
            loading={ loading }
            save={ save }
            updating={ updating }
            removing={ false }
            remove={ remove }
            validate={ validateByDto(t) }
            title={ `Request №${record?.id}` }
            close={ close }
            initialValues={ serializeRecord(UpdateRegistrationRequestDto, record) }
        >
            { ({
                values, errors, touched, handleChange, setFieldValue,
            }) => (
                <React.Fragment>
                    <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
                        <Input
                            label="Phone"
                            value={ values.phone }
                            onChange={ handleChange('phone') }
                            error={ touched.phone && errors.phone }
                            required={ true }
                        />
                        <Input
                            label="Email"
                            value={ values.email }
                            onChange={ handleChange('email') }
                            error={ touched.email && errors.email }
                            required={ true }
                        />
                        <Input
                            label="Website"
                            value={ values.site }
                            onChange={ handleChange('site') }
                            error={ touched.site && errors.site }
                            required={ true }
                        />

                        { values.status !== RegistrationRequestStatusEnum.DONE && (
                            <Select
                                dataTestId="status"
                                block={ true }
                                multiple={ false }
                                label="Status"
                                options={ enumToOptions(RegistrationRequestStatusEnum).filter(
                                    (a) => a.key !== RegistrationRequestStatusEnum.DONE,
                                ) }
                                selected={ values.status }
                                onChange={ ({ selected }) => {
                                    setFieldValue('status', selected?.key);
                                } }
                                optionsListWidth="field"
                            />
                        ) }

                        <Textarea
                            label="Comment"
                            value={ values.comment }
                            onChange={ handleChange('comment') }
                            required={ values.status === RegistrationRequestStatusEnum.REJECTED }
                        />

                        { values.status === RegistrationRequestStatusEnum.NEW && (
                            <Button
                                view="primary"
                                onClick={ () => {
                                    openOrganizationModal({
                                        requestId: record.id,
                                        cb: () => {
                                            close();
                                            cb();
                                        },
                                    });
                                } }
                            >
                                Create profile
                            </Button>
                        ) }
                    </FlexColumns>
                </React.Fragment>
            ) }
        </RestForm>
    );
};
