import React from 'react';
import { FlexColumns, Input, useCrudFormRequests } from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { AwardRO } from '@escapenavigator/types/dist/questroom/awards/award.ro';
import { CreateAwardDto } from '@escapenavigator/types/dist/questroom/awards/create-award.dto';
import { serializeRecord, validateByDto } from '@escapenavigator/utils/dist';
import { TFunction } from 'i18next';
import { useApi } from 'src/providers/api/context';

type Props = {
    questroomId: number;
    award?: AwardRO;
    close: () => void;
    t: TFunction;
};

export const QuestroomAward = ({
    close, t, award, questroomId,
}: Props) => {
    const { questrooms } = useApi();

    const {
        save,
        remove,
        updating,
        removing,
        error: softError,
    } = useCrudFormRequests({
        recordId: award?.id,
        saveRequest: award?.id ? questrooms.updateAward : questrooms.createAward,
        removeRequest: questrooms.removeAward,
        close,
    });

    return (
        <RestForm
            t={ t }
            updating={ updating || removing }
            save={ save }
            softError={ softError }
            remove={ remove }
            validate={ validateByDto(t) }
            title="Award"
            close={ close }
            initialValues={ serializeRecord(CreateAwardDto, award || { questroomId }) }
        >
            { ({
                values, errors, touched, handleChange,
            }) => (
                <FlexColumns columns={ 1 }>
                    <Input
                        label="Title"
                        value={ values.title }
                        onChange={ handleChange('title') }
                        error={ touched.title && errors.title }
                        block={ true }
                    />
                    <Input
                        label="Type"
                        value={ values.type }
                        onChange={ handleChange('type') }
                        error={ touched.type && errors.type }
                        block={ true }
                    />
                    <Input
                        label="Year"
                        value={ values.year }
                        onChange={ handleChange('year') }
                        error={ touched.year && errors.year }
                        block={ true }
                    />
                </FlexColumns>
            ) }
        </RestForm>
    );
};
