import React from 'react';
import { MessageM } from '@alphakits/icons';
import { Flex, IconButton } from '@alphakits/ui/dist';
import { TableColumns } from '@alphakits/ui/dist/table/types';
import { QuestroomAdminRO } from '@escapenavigator/types/dist/questroom/questroom-admin.ro';

import { GenerateYTeaser } from './generate-teaser';

export const columns = (
    onReviewClick: (row: QuestroomAdminRO) => void,
): TableColumns<QuestroomAdminRO> => [
    {
        header: 'Title',
        accessor: 'title',
        sort: true,
        row: {
            image: 'photo',
            subtitle: 'slug',
        },
    },
    {
        header: 'Teaser',
        accessor: 'teaser',
        sort: true,
    },
    {
        header: 'Options',
        accessor: 'navigatorReviewId',
        sort: true,
        row: {
            title: ({ row }) => (
                <Flex gap="md">
                    <IconButton
                        style={ { color: row.navigatorReviewId ? 'blue' : 'grey' } }
                        icon={ MessageM }
                        onClick={ (e) => {
                            e.stopPropagation();
                            onReviewClick(row);
                        } }
                    />
                    { !row.teaser && <GenerateYTeaser id={ row.id } /> }
                </Flex>
            ),
        },
    },
];
