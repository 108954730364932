import React from 'react';
import { TabPanel, Tabs } from 'src/components/tabs';
import { DefaultPageProps } from 'src/types';

import { ReviewsNavigator } from './navigator';
import { ReviewsSocial } from './social';

export const Reviews: React.FC<DefaultPageProps> = () => (
    <Tabs view="header" defaultActive="Experts">
        <TabPanel name="Experts">
            <ReviewsNavigator />
        </TabPanel>

        <TabPanel name="Social">
            <ReviewsSocial />
        </TabPanel>
    </Tabs>
);
