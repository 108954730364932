import React, { useContext } from 'react';
import {
    Button, FlexColumns, ModalContext, Sidepanel, Typography,
} from '@alphakits/ui';
import { QuestroomAdminRO } from '@escapenavigator/types/dist/questroom/questroom-admin.ro';

import { QuestroomAward } from '../questroom-award';

type Props = {
    questroom: QuestroomAdminRO;
};

export const QuestroomModal = ({ questroom }: Props) => {
    const { openModal } = useContext(ModalContext);

    return (
        <Sidepanel title={ questroom.title }>
            <FlexColumns columns={ 1 } gr={ 42 }>
                <FlexColumns columns={ 1 } gr={ 16 }>
                    <Typography.Title view="xsmall" weight="bold" tag="div">
                        Awards
                    </Typography.Title>

                    { questroom.awards.map((award) => (
                        <Button
                            view="ghost"
                            onClick={ () =>
                                openModal(QuestroomAward)({ award, questroomId: questroom.id }) }
                        >
                            <Typography.Text view="caps">{ JSON.stringify(award) }</Typography.Text>
                        </Button>
                    )) }
                    <div>
                        <Button
                            view="outlined"
                            size="xs"
                            onClick={ () => openModal(QuestroomAward)({ questroomId: questroom.id }) }
                        >
                            Add award
                        </Button>
                    </div>
                </FlexColumns>

                <FlexColumns columns={ 1 } gr={ 0 }>
                    { Object.entries(questroom)
                        .sort()
                        .map(([key, value]) => (
                            <Typography.Text view="caps">
                                <b>{ key }</b>: { JSON.stringify(value) }
                            </Typography.Text>
                        )) }
                </FlexColumns>
            </FlexColumns>
        </Sidepanel>
    );
};
