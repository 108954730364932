import React, { useContext } from 'react';
import { ModalContext, Table } from '@alphakits/ui';
import { PlayerReviewModal } from 'src/modals/player-review';
import { useApi } from 'src/providers/api/context';
import { mapper } from 'src/tags';
import { DefaultPageProps } from 'src/types';

import { columns } from './columns';

export const PlayersPage: React.FC<DefaultPageProps> = () => {
    const { openModal } = useContext(ModalContext);
    const addReview = (playerId: number) =>
        openModal(PlayerReviewModal)({ playerId, cb: () => {} });

    const { playersApi } = useApi();

    return (
        <Table.TableComponent
            tagsBuilder={ mapper() }
            // onRowClick={ (record) => openRequest({ record, cb: () => queryFetch(undefined) }) }
            // onCreateClick={ () => openRequest() }
            columns={ columns(addReview) }
            searchPlaceholder="Serach by title"
            language="en"
            promise={ playersApi.query }
        />
    );
};
