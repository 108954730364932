import React from 'react';
import { CalendarInput, FlexColumns, useCrudFormRequests } from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { SocialReviewRO } from '@escapenavigator/types/dist/review/social-review.ro';
import { UpdateSocialReviewDto } from '@escapenavigator/types/dist/review/update-social-review.dto';
import { serializeRecord, validateByDto } from '@escapenavigator/utils/dist';
import { format } from 'date-fns';
import { TFunction } from 'i18next';
import { useApi } from 'src/providers/api/context';

type Props = {
    review: SocialReviewRO;
    close: () => void;
    cb: () => void;
    t: TFunction;
};

export const UpdateReview = ({
    close, t, cb, review,
}: Props) => {
    const { socialReviews } = useApi();

    const {
        save,
        remove,
        updating,
        removing,
        error: softError,
    } = useCrudFormRequests({
        recordId: review.id,
        saveRequest: socialReviews.update,
        removeRequest: socialReviews.remove,
        close,
        saveCallback: () => {
            if (cb) cb();
        },
    });

    return (
        <RestForm
            t={ t }
            updating={ updating || removing }
            save={ save }
            softError={ softError }
            remove={ remove }
            validate={ validateByDto(t) }
            title="Update translation"
            close={ close }
            initialValues={ serializeRecord(UpdateSocialReviewDto, review) }
        >
            { ({
                values, errors, touched, setFieldValue,
            }) => (
                <FlexColumns columns={ 1 }>
                    <CalendarInput
                        lang="en"
                        block={ true }
                        label="Date"
                        value={ format(new Date(values.createdAt), 'yyyy-MM-dd') }
                        error={ touched.createdAt && errors.createdAt }
                        onChange={ (e, { date }) => setFieldValue('createdAt', +date) }
                    />
                </FlexColumns>
            ) }
        </RestForm>
    );
};
