import React, { useContext } from 'react';
import { ModalContext, Table } from '@alphakits/ui';
import { NavigatorReviewModal } from 'src/modals/navigator-review';
import { useApi } from 'src/providers/api/context';
import { DefaultPageProps } from 'src/types';

import { columns } from './columns';

export const ReviewsNavigator: React.FC<DefaultPageProps> = () => {
    const { openModal } = useContext(ModalContext);

    const openReview = openModal(NavigatorReviewModal, 'm');

    const { expertsReviews } = useApi();

    return (
        <React.Fragment>
            <Table.TableComponent
                onRowClick={ ({ id }, cb) => openReview({ recordId: +id, cb }) }
                columns={ columns }
                searchPlaceholder="Serach"
                language="en"
                promise={ expertsReviews.query }
            />
        </React.Fragment>
    );
};
